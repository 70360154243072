<template>
  <section class="sample-request-edit-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="sampleRequestData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching sample request data
      </h4>
      <div class="alert-body">
        No sample request found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-sample-requests-list'}"
        >
          Sample Requests
        </b-link>
        list.
      </div>
    </b-alert>

    <b-row v-if="sampleRequestData">

      <b-col
        cols="12"
      >
        <b-button
          variant="outline-primary"
          size="md"
          class="mb-2"
          @click="goBack"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="16"
            class="mr-2 mr-sm-50"
          />Back
        </b-button>
        <b-card>

          <b-card-body>
            <div class="d-flex mt-1 mb-1 justify-content-between">
              <div class="mr-5">
                <b-media
                  left-align
                  vertical-align="center"
                >
                  <template #aside>
                    <b-link
                      v-if="sampleRequestData.image"
                      :to="{ name: 'apps-styles-edit', params: { id: sampleRequestData.style_id }}"
                    >
                      <b-img
                        rounded
                        height="80"
                        :src="sampleRequestData.image"
                      />
                    </b-link>
                  </template>
                  <h4 class="card-title mt-0 mb-1">
                    {{ sampleRequestData.name }}
                  </h4>
                  <h6 class="card-subtitle text-muted">
                    <b-link
                      :to="{ name: 'apps-styles-edit', params: { id: sampleRequestData.style_id }}"
                    >{{ sampleRequestData.ref }}</b-link>
                  </h6>
                </b-media>
              </div>
              <div class="mr-5">
                <h5 class="mt-0 mb-1 text-muted">
                  Brand
                </h5>
                <h6 class="card-subtitle ">
                  {{ sampleRequestData.brand }}
                </h6>
              </div>
              <div class="mr-5">
                <h5 class="mt-0 mb-1 text-muted">
                  Sample Type
                </h5>
                <h6 class="card-subtitle ">
                  {{ sampleRequestData.sample_type }}
                </h6>
              </div>
              <div class="mr-5">
                <h5 class="mt-0 mb-1 text-muted">
                  Deadline
                </h5>
                <h6 class="card-subtitle ">
                  {{ sampleRequestData.deadline }}
                </h6>
              </div>
              <div class="mr-5">
                <h5 class="mt-0 mb-1 text-muted">
                  ETD
                </h5>
                <h6 class="card-subtitle ">
                  {{ sampleRequestData.estimate }}
                </h6>
              </div>
              <div>
                <h5 class="mt-0 mb-1 text-muted">
                  Sample Status
                </h5>
                <b-dropdown
                  :text="sampleRequestData.sample_status"
                  :right="$store.state.appConfig.isRTL"
                  :disabled="!sampleRequestData.sample_status_options.length"
                >
                  <b-dropdown-item
                    v-for="option in sampleRequestData.sample_status_options"
                    :key="sampleRequestData.id + option"
                    @click="setSampleRequestStatus(sampleRequestData.id, option)"
                  >
                    <span class="align-middle ml-50">{{ option }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <b-tabs
              v-model="tabIndex"
              pills
            >
              <!-- Tab: Basic -->
              <b-tab active>
                <template #title>
                  <feather-icon
                    icon="ImageIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Main Sample Info</span>
                </template>
                <sample-request-edit-tab-basic
                  class="mt-2 pt-75"
                  :sample-request-data="sampleRequestData"
                  :sample-type-options="sampleTypeOptions"
                  @refresh-data="fetchSampleRequest"
                  @scroll-to-bottom="scrollToBottom"
                />
              </b-tab>
              <template v-if="canComment(sampleRequestData)">
                <b-tab>
                  <template #title>
                    <feather-icon
                      :icon="feedConfig.icons.Measurement"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Measurements Approvals</span>
                  </template>
                  <sample-request-edit-tab-measurements
                    class="mt-2 pt-75"
                    :sample-request-data="sampleRequestData"
                    @refresh-data="fetchSampleRequest"
                    @scroll-to-bottom="scrollToBottom"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon
                      :icon="feedConfig.icons['Design Construction']"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Design Construction Approvals</span>
                  </template>
                  <sample-request-edit-tab-design
                    class="mt-2 pt-75"
                    :sample-request-data="sampleRequestData"
                    @refresh-data="fetchSampleRequest"
                    @scroll-to-bottom="scrollToBottom"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon
                      :icon="feedConfig.icons.Embellishment"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Embellishment Approvals</span>
                  </template>
                  <sample-request-edit-tab-embellishment
                    class="mt-2 pt-75"
                    :sample-request-data="sampleRequestData"
                    @refresh-data="fetchSampleRequest"
                    @scroll-to-bottom="scrollToBottom"
                  />
                </b-tab>
              </template>
            </b-tabs>
          </b-card-body>
        </b-card>
        <b-card v-if="sampleRequestData.feed.length > 0">
          <b-card-body>
            <feed
              ref="refFeed"
              :feed="sampleRequestData.feed"
              :feed-config="feedConfig"
              :tab-index="tabIndex"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert, BButton, BCard, BCardBody, BCol, BDropdown, BDropdownItem,
  BImg, BLink, BMedia, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted, onUnmounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'
import store from '@/store'
import sampleRequestStoreModule from '../sampleRequestStoreModule'
import SampleRequestEditTabBasic from './SampleRequestEditTabBasic.vue'
import SampleRequestEditTabDesign from './SampleRequestEditTabDesign.vue'
import SampleRequestEditTabEmbellishment from './SampleRequestEditTabEmbellishment.vue'
import SampleRequestEditTabMeasurements from './SampleRequestEditTabMeasurements.vue'
import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BCardBody,
    BCol,
    BDropdown,
    BDropdownItem,
    BImg,
    BLink,
    BMedia,
    BRow,
    BTab,
    BTabs,

    Feed,
    SampleRequestEditTabBasic,
    SampleRequestEditTabDesign,
    SampleRequestEditTabMeasurements,
    SampleRequestEditTabEmbellishment,
  },
  setup(props, { root, refs }) {
    const sampleRequestData = ref(null)
    const tabIndex = ref(0)

    const APP_STORE_MODULE_NAME = 'app-sample-request'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) {
      store.registerModule(APP_STORE_MODULE_NAME, sampleRequestStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) {
        store.unregisterModule(APP_STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'

    const sampleTypeOptions = computed(() => {
      const options = root.$store.getters['app-sample-request/sampleTypeOptions']
      return options
    })
    const optionsInit = () => {
      root.$store.dispatch('app-sample-request/fetchFormOptions')
    }

    const scrollToBottom = () => {
      const yOffset = -100
      if (refs.refFeed) {
        const el = refs.refFeed.$el.firstElementChild
        const elTop = el.lastElementChild.getBoundingClientRect().top
        window.scrollTo({
          top: elTop + window.pageYOffset + yOffset,
          behavior: 'smooth',
        })
      }
    }

    const fetchSampleRequest = () => {
      store.dispatch('app-sample-request/fetchSampleRequest', { id: router.currentRoute.params.id })
        .then(response => { sampleRequestData.value = response.data })
        .catch(error => {
          if (error.response.status === 404) {
            sampleRequestData.value = undefined
          }
        })
    }

    const setSampleRequestStatus = (id, status) => {
      store.dispatch('app-sample-request/updateSampleRequest',
        { id, sample_status: status })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status modified',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          fetchSampleRequest()
        })
        .catch(({ response }) => {
          const { data } = response
          if (data.sample_status) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: data.sample_status[0],
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    const canComment = ({ sample_status }) => (
      ['Received', 'Commented'].includes(sample_status)
    )

    const feedConfig = {
      icons: {
        Measurement: 'SlidersIcon',
        'Design Construction': 'StarIcon',
        Embellishment: 'SunIcon',
      },
      headers: {
        Measurement: 'approval-header',
        'Design Construction': 'approval-header',
        Embellishment: 'approval-header',
        'New Sample Request': 'title-header',
        'Sample Requested': 'title-header',
        'Sample Received': 'title-header',
        'Sample Confirmed': 'title-header',
        'Sample Ready to make': 'title-header',
        'Sample Sent': 'title-header',
        'Sample Updated': 'title-header',
      },
      feedBody: {
        Measurement: 'measurement-comments-body',
        'Design Construction': 'comments-body',
        Embellishment: 'comments-body',
        Comment: 'comments-body',
        'New Sample Request': 'new-sample-request-body',
        'Sample Requested': 'sample-requested-body',
        'Sample Received': 'sample-received-body',
        'Sample Confirmed': 'sample-confirmed-body',
        'Sample Ready to make': 'sample-ready-to-make-body',
        'Sample Sent': 'sample-sent-body',
        'Sample Updated': 'sample-updated-body',
      },
    }

    const goBack = () => {
      router.go(-1)
    }

    onMounted(optionsInit)
    onMounted(fetchSampleRequest)

    return {
      isBrand,
      tabIndex,
      sampleRequestData,
      fetchSampleRequest,
      setSampleRequestStatus,
      goBack,
      scrollToBottom,
      canComment,
      feedConfig,

      sampleTypeOptions,
    }
  },
}
</script>

<style>

</style>
