<template>
  <div>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
      >

        <!-- Sample Type -->
        <validation-provider
          #default="validationContext"
          name="Sample Type"
          rules="required"
        >
          <b-form-group
            label="Sample Type (required)"
            label-for="sample-type"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="formData.sample_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sampleTypeOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="sample-type"
              aria-required="true"
              :disabled="isBrand"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Deadline Date -->
        <validation-provider
          #default="validationContext"
          name="Deadline"
          rules="required"
        >
          <b-form-group
            label="Deadline (required)"
            label-for="deadline"
            :state="getValidationState(validationContext)"
          >
            <b-form-datepicker
              v-model="formData.deadline"
              required
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :disabled="isBrand"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Estimate Date -->
        <b-form-group
          label="Estimated Time of Departure (ETD)"
          label-for="estimate"
        >
          <b-form-datepicker
            v-model="formData.estimate"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :disabled="isBrand"
          />
        </b-form-group>

        <!-- Sample Size -->
        <b-form-group
          label="Sample Size"
          label-for="sample-size"
        >
          <b-form-select
            v-model="formData.sample_size"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sampleRequestData.sizes_ordering"
            input-id="sample-size"
            :disabled="isBrand"
          />
        </b-form-group>

        <!-- Sample Quantity -->
        <b-form-group
          label="Sample Quantity"
          label-for="sample-quantity"
        >
          <b-form-input
            id="sample-quantity"
            v-model="formData.sample_quantity"
            type="number"
            :disabled="isBrand"
          />
        </b-form-group>

        <!-- Tracking Info -->
        <b-form-group
          label="Tracking Info"
          label-for="tracking-info"
        >
          <b-form-input
            id="tracking-info"
            v-model="formData.tracking_info"
            trim
            :disabled="isBrand"
          />
        </b-form-group>

        <!-- Comments -->
        <b-form-group
          label="Comments"
          label-for="comments"
        >
          <b-form-textarea
            id="comments"
            v-model="formData.comments"
            trim
            rows="3"
            max-rows="5"
          />
        </b-form-group>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
        >
          Update or Comment
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormDatepicker, BFormGroup, BFormInput,
  BFormInvalidFeedback, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BFormTextarea,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    sampleRequestData: {
      type: Object,
      required: true,
    },
    sampleTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'

    const formData = ref({
      sample_type: props.sampleRequestData.sample_type,
      deadline: props.sampleRequestData.deadline,
      estimate: props.sampleRequestData.estimate,
      sample_size: props.sampleRequestData.sample_size,
      sample_quantity: props.sampleRequestData.sample_quantity,
      tracking_info: props.sampleRequestData.tracking_info,
      comments: '',
    })

    const onSubmit = () => {
      const sampleRequestData = {
        id: router.currentRoute.params.id,
        ...formData.value,
      }
      store.dispatch('app-sample-request/updateSampleRequest', sampleRequestData)
        .then(() => {
          emit('refresh-data')
          formData.value.comments = ''
          emit('scroll-to-bottom')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sample Request edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      isBrand,
      onSubmit,
      formData,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
