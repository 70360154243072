import axios from '@axios'

export default {
  namespaced: true,
  state: {
    formOptions: {},
  },
  getters: {
    sampleStatusOptions: state => {
      const { sample_status: sampleStatus } = state.formOptions
      return sampleStatus ? sampleStatus.options : []
    },
    sampleTypeOptions: state => {
      const { sample_types: sampleTypes } = state.formOptions
      return sampleTypes ? sampleTypes.options : []
    },
    brandContactUsersOptions: state => {
      const { brand_contact_users: brandContactUsers } = state.formOptions
      return brandContactUsers ? brandContactUsers.options : []
    },
    brandsOptions: state => {
      const { brands } = state.formOptions
      return brands ? brands.options : []
    },
  },
  mutations: {
    UPDATE_FORM_OPTIONS(state, formOptions) {
      state.formOptions = formOptions
    },
  },
  actions: {
    fetchFormOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles/styles_options/')
          .then(response => {
            commit('UPDATE_FORM_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchStyleSampleRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles-sample-request-list/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSampleRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-sample-request/${id}/`, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSampleRequest(ctx, { id, ...sampleRequestData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-sample-request/${id}/`, sampleRequestData, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFeed(ctx, feedData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/sample-request-feed-add/', feedData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
